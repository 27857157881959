import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { convertTimestamp } from 'src/utils/timeHelpers';

import Tooltip from 'src/components/ui/Tooltip';
import { selectStepsDisabledStatus } from 'src/slices/qamodules.slice';

const EventLogWrapper = styled.div`
    padding: 4px 8px 2px;

    font-family: 'SF Mono', monospace;
    font-size: 14px;

    --eventLogTimeColor: var(--grey-3);
    --eventLogValueColor: var(--grey-1);
    --eventLogValueBGColor: ${({ $eventNameBgColor }) => ($eventNameBgColor ? `var(${$eventNameBgColor})` : 'var(--grey-8)')};

    @container (width < 315px) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
    }

    ${({ $isInteractionError }) => $isInteractionError && `
        --eventLogValueBGColor: var(--rose-1);
        --eventLogValueColor: #fff;
        --eventLogTimeColor: var(--rose-1);
    `}
`;

const EventLogTime = styled.span`
    display: inline-block;
    vertical-align: middle;
    margin: 0 8px 0 0;
    color: var(--eventLogTimeColor);
    &:before {
        content: '[';
    }
    &:after {
        content: ']';
    }

    @container (width < 315px) {
        display: none;
    }
`;

const EventLogValue = styled.span`
    display: inline-block;
    vertical-align: middle;
    padding: 2px 6px;
    border-radius: 4px;
    color: var(--eventLogValueColor);
    background: var(--eventLogValueBGColor);
`;

const TooltipIcon = styled.span`
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 8px;
`;

const TOTAL_AD_AMOUNT = {
    1: 'Small',
    2: 'Medium',
    3: 'Large',
};

const EVENT_NAME_MAP = {
    pokiTrackingSdkStatusInitialized: { name: 'SDK initialized', bgColor: '--data-blue-1' },
    pokiTrackingScreenGameLoadingFinished: { name: 'Game loading finished', bgColor: '--data-blue-1' },
    pokiTrackingScreenGameplayStart: { name: 'Gameplay start', bgColor: '--data-green-1' },
    pokiTrackingScreenGameplayStop: { name: 'Gameplay stop', bgColor: '--data-green-1' },
    pokiTrackingScreenRewardedBreak: { name: 'Rewarded break', bgColor: '--data-orange-1', options: { size: null } },
    pokiTrackingScreenCommercialBreak: { name: 'Commercial break', bgColor: '--data-orange-1' },
    pokiTrackingScreenDisplayAdRequest: { name: 'Display ad request', bgColor: '--data-orange-1' },
    pokiTrackingScreenDisplayAdDestroy: { name: 'Display ad destroy', bgColor: '--data-orange-1' },
    pokiTrackingMeasure: { name: 'Measure', bgColor: '--data-orange-1' },
};

// events that need to display additional data
const CUSTOM_EVENTS = ['pokiTrackingMeasure'];

const getTooltipContent = (data) => {
    if (!data) return null;

    return (
        <pre>
            <code>{JSON.stringify(data, null, 2)}</code>
        </pre>
    );
};

export default function EventLog({ timestamp, eventName, eventData }) {
    const { action, category, label, totalAdAmount } = eventData || {};

    const [hasGameplayInteraction, setHasGameplayInteraction] = useState(false);

    const stepDisabledStatus = useSelector(selectStepsDisabledStatus);

    const time = convertTimestamp(timestamp); // convert timestamp to [hh:mm:ss format]
    const event = EVENT_NAME_MAP[eventName]; // get event name and color from map

    // custom event might contain additional data and we need to display it
    const isCustomEvent = CUSTOM_EVENTS.includes(eventName) && eventData;
    const isRewardedBreak = eventName === 'pokiTrackingScreenRewardedBreak';

    // this is a special case when gameplay start event is fired without interaction
    const isGameplayStart = eventName === 'pokiTrackingScreenGameplayStart';
    const isGameplayStartDisabled = stepDisabledStatus?.['sdk-basics-gameplay-start'];

    useEffect(() => {
        // if gameplay start event is fired without interaction we display an error in the log
        if (isGameplayStart) {
            setHasGameplayInteraction(eventData?.interaction ?? false);
        }
    }, [isGameplayStart, eventData]);

    const extendedEvent = {
        ...event,
        options: {
            ...event.options,
            ...(isRewardedBreak && totalAdAmount && { size: TOTAL_AD_AMOUNT[totalAdAmount] }),
        },
    };

    const formattedEventData = { action, category, label };

    const renderEventAsTooltip = ({ data, error = false }) => (
        <Tooltip arrow content={getTooltipContent(data)} placement="top" maxWidth="100%">
            <EventLogTime>{time}</EventLogTime>
            <EventLogValue $error={error}>
                {extendedEvent?.name || eventName}
                <TooltipIcon>
                    ⓘ
                </TooltipIcon>
            </EventLogValue>
        </Tooltip>
    );

    const renderEventLogValue = () => {
        if (isGameplayStart && !hasGameplayInteraction && isGameplayStartDisabled) {
            return renderEventAsTooltip({ data: 'Gameplay was fired without a player interaction', error: true });
        }

        if (Object.keys(extendedEvent?.options).length > 0) {
            return renderEventAsTooltip({ data: extendedEvent.options });
        }

        if (isCustomEvent) {
            return renderEventAsTooltip({ data: formattedEventData });
        }

        return (
            <>
                <EventLogTime>{time}</EventLogTime>
                <EventLogValue>
                    {extendedEvent?.name || eventName}
                </EventLogValue>
            </>
        );
    };

    return (
        extendedEvent && (
            <EventLogWrapper
                $eventNameBgColor={extendedEvent?.bgColor}
                $isInteractionError={isGameplayStart && !hasGameplayInteraction && isGameplayStartDisabled}
            >
                {renderEventLogValue()}
            </EventLogWrapper>
        )
    );
}

EventLog.propTypes = {
    timestamp: PropTypes.number.isRequired,
    eventName: PropTypes.string.isRequired,
    // we don't know what data will be passed to custom event
    // eslint-disable-next-line react/forbid-prop-types
    eventData: PropTypes.object,
};

EventLog.defaultProps = {
    eventData: null,
};
